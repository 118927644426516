// Import the necessary AWS SDK clients and commands
//import { RekognitionClient, CreateCollectionCommand, IndexFacesCommand } from '@aws-sdk/client-rekognition';
import { createCollection, registerFace, uploadImage } from './AWS RekognitionAPI Functions';


export async function RegisterFace (s3Client, bucketName, imageName, imageFile, client, collectionId){
    console.log("SUBMIT IMAGE ");

    try {
      // Step 1: Upload the image to S3
      await uploadImage(s3Client, bucketName, imageName, imageFile);

      // Step 2: Create the collection in Rekognition
      await createCollection(client, collectionId);

      // Step 3: Register the face using Rekognition
      await registerFace(client, collectionId, bucketName, imageName);

    } catch (error) {
      console.error("An error occurred during the process:", error);
    }
    
  };
