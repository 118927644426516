import { ListCollectionsCommand } from "@aws-sdk/client-rekognition";

import {
  RekognitionClient,
  CreateCollectionCommand,
  IndexFacesCommand,
} from "@aws-sdk/client-rekognition";

import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3";

// Initialize the S3 client with region and credentials
export const createS3_Client = (region, accessKeyId, secretAccessKey) => {
  return new S3Client({
    region: 'us-west-2', //region,  // Replace with your bucket's region
    credentials: {
      accessKeyId: accessKeyId, //'YOUR_ACCESS_KEY_ID', // Replace with your access key ID
      secretAccessKey: secretAccessKey, //'YOUR_SECRET_ACCESS_KEY', // Replace with your secret access key
    },
  });
};

// Initialize Rekognition client with your credentials
export const createRekognitionClient = (
  region,
  accessKeyId,
  secretAccessKey
) => {
  return new RekognitionClient({
    region: 'us-west-2',//region, // Dynamic region passed as parameter
    credentials: {
      accessKeyId: accessKeyId, // Dynamic Access Key ID passed as parameter
      secretAccessKey: secretAccessKey, // Dynamic Secret Access Key passed as parameter
    },
  });
};

//function to upload image to S3
export async function uploadImage(s3Client, bucketName, imageName, imageFile) {
  const params = {
    Bucket: bucketName,
    Key: imageName,
    Body: imageFile, // imageFile can be a Blob, Buffer, or ReadableStream
  };

  try {
    const command = new PutObjectCommand(params);
    const response = await s3Client.send(command);
    console.log(`Image uploaded successfully: ${imageName}`);
    return response;
  } catch (error) {
    console.error("Error uploading image:", error);
  }
}

// Function to check if a collection exists
export async function doesCollectionExist(client, collectionId) {
  try {
    const command = new ListCollectionsCommand({});
    const response = await client.send(command);

    return response.CollectionIds.includes(collectionId);
  } catch (error) {
    console.error("Error checking collections:", error);
    return false;
  }
}

// Function to create a collection
export async function createCollection(client, collectionId) {
  const params = {
    CollectionId: collectionId,
  };

  try {
    const command = new CreateCollectionCommand(params);
    const response = await client.send(command);
    console.log("Collection created:", response);
    return response;
  } catch (error) {
    console.error("Error creating collection:", error);
  }
}

// Function to list all collections
export async function listCollections(client) {
  try {
    const command = new ListCollectionsCommand({});
    const response = await client.send(command);
    console.log("Collections:", response.CollectionIds);
  } catch (error) {
    console.error("Error listing collections:", error);
  }
}

// Function to register a face in the collection
export async function registerFace(
  client,
  collectionId,
  bucketName,
  imageName
) {
  const params = {
    CollectionId: collectionId,
    Image: {
      S3Object: {
        Bucket: bucketName,
        Name: imageName,
      },
    },
    DetectionAttributes: ["ALL"],
  };

  try {
    const command = new IndexFacesCommand(params);
    const response = await client.send(command);
    console.log("Face indexed:", response);
    return response;
  } catch (error) {
    console.error("Error indexing face:", error);
  }
}
